var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-and-inspection" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "card-container",
      },
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "single-item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.pageJump(item)
              },
            },
          },
          [
            _c("div", { staticClass: "label-line" }, [
              _c("img", {
                attrs: {
                  src: require(`@/assets/images/bms/order-inspection-${item.type}.png`),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "label-span" }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        "),
              ]),
            ]),
            _c("div", { staticClass: "value-line" }, [
              _vm._v("\n        " + _vm._s(item.value) + "\n      "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }