var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "property-statistics-component" }, [
    _c(
      "div",
      { staticClass: "project-item-line" },
      [_c("ParkSelection", { on: { getPark: _vm.getParkCallback } })],
      1
    ),
    _c("div", { staticClass: "instrument-management-container" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("仪表管理")]),
      _c(
        "div",
        { staticClass: "card-container" },
        [_c("InstrumentManagement")],
        1
      ),
    ]),
    _c("div", { staticClass: "meter-reading-container" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("抄表")]),
      _c("div", { staticClass: "card-container" }, [_c("MeterReading")], 1),
    ]),
    _c("div", { staticClass: "order-inspection-container" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("工单 & 巡检")]),
      _c(
        "div",
        { staticClass: "card-container" },
        [_c("OrderAndInspection")],
        1
      ),
    ]),
    _c("div", { staticClass: "customer-service-container" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("客户服务")]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "single-card" }, [_c("NewSuggestions")], 1),
        _c("div", { staticClass: "single-card" }, [_c("NewPayment")], 1),
        _c("div", { staticClass: "single-card" }, [_c("HighFrequencyWord")], 1),
        _c("div", { staticClass: "single-card" }, [_c("AverageScore")], 1),
        _c("div", { staticClass: "single-card" }, [_c("ElectronicInvoice")], 1),
      ]),
    ]),
    _c("div", [_c("QuickLink", { attrs: { linkList: _vm.linkData } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }