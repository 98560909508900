<template>
  <div class="property-statistics-component">
    <!-- 项目切换 -->
    <div class="project-item-line">
      <ParkSelection @getPark="getParkCallback" />
    </div>
    <!-- 仪表管理 -->
    <div class="instrument-management-container">
      <div class="title-line">仪表管理</div>
      <div class="card-container">
        <InstrumentManagement />
      </div>
    </div>
    <!-- 抄表 -->
    <div class="meter-reading-container">
      <div class="title-line">抄表</div>
      <div class="card-container">
        <MeterReading />
      </div>
    </div>
    <!-- 工单 & 巡检 -->
    <div class="order-inspection-container">
      <div class="title-line">工单 & 巡检</div>
      <div class="card-container">
        <OrderAndInspection />
      </div>
    </div>
    <!-- 客户服务 -->
    <div class="customer-service-container">
      <div class="title-line">客户服务</div>
      <div class="card-container">
        <!-- 本月新增投诉建议 -->
        <div class="single-card">
          <NewSuggestions />
        </div>
        <!-- 本月新增缴费 -->
        <div class="single-card">
          <NewPayment />
        </div>
        <!-- 客户报修高频词汇分析 -->
        <div class="single-card">
          <HighFrequencyWord />
        </div>
        <!-- 本月用户评价平均分 -->
        <div class="single-card">
          <AverageScore />
        </div>
        <!-- 本月开具电子发票 -->
        <div class="single-card">
          <ElectronicInvoice />
        </div>
      </div>
    </div>
    <!-- 快捷链接 -->
    <div>
      <QuickLink :linkList="linkData"></QuickLink>
    </div>
  </div>
</template>

<script>
import QuickLink from "../../components/QuickLink/index.vue";
import ParkSelection from "../../components/ParkSelection/index.vue";
import InstrumentManagement from "./components/instrumentsManagement.vue";
import MeterReading from "./components/meterReading.vue";
import OrderAndInspection from "./components/orderAndInspection.vue";
import NewSuggestions from "./components/newSuggestions.vue";
import NewPayment from "./components/newPayment.vue";
import HighFrequencyWord from "./components/highFrequencyWord.vue";
import AverageScore from "./components/averageScore.vue";
import ElectronicInvoice from "./components/electronicInvoice.vue";

export default {
  name: "propertyStatisticsComponent",
  components: {
    ParkSelection,
    QuickLink,
    InstrumentManagement,
    MeterReading,
    OrderAndInspection,
    NewSuggestions,
    NewPayment,
    HighFrequencyWord,
    AverageScore,
    ElectronicInvoice,
  },
  data() {
    return {
      linkData: [
        // {
        //   title: "BMS 资产管理",
        //   iconPathOff: require("@/assets/images/bms/link_set.png"),
        //   iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
        //   urlType: true,
        //   linkUrl: "",
        // },
        {
          title: "BMS SOP管理",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.currentLinkRender("/bms/SOPManagement"),
        },
        {
          title: "CRM 回访",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/visit"),
        },
      ],
    };
  },
  methods: {
    crmLinkRender(path) {
      const pathMap = {
        DEV: "https://test-crm.rlinkiot.com/#",
        SIT: "https://test-crm.rlinkiot.com/#",
        UAT: "https://test-crm.rlinkiot.com/#",
        PROD: "https://crm.rlinkiot.com/#",
      };
      return `${pathMap[process.env.VUE_APP_ENV]}${path}?auth-token=${
        this.$store.getters.baseAuthToken
      }`;
    },
    currentLinkRender(path) {
      const pathMap = {
        DEV: "https://sit.rlinkiot.com/console/#",
        SIT: "https://sit.rlinkiot.com/console/#",
        UAT: "https://uat.rlinkiot.com/console/#",
        PROD: "https://prod.rlinkiot.com/console/#",
      };
      return pathMap[process.env.VUE_APP_ENV] + path;
    },
    getParkCallback(index, projectId, rugeProjectId) {
      this.$eventBus.$emit("propertyStatisticsFresh", {
        index,
        projectId,
        rugeProjectId,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.property-statistics-component {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .project-item-line {
    height: 40px;
  }
  .common-card {
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
  }
  .title-line {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin-bottom: 16px;
    margin-top: 40px;
  }
  .instrument-management-container {
    .card-container {
    }
  }
  .meter-reading-container {
    .card-container {
    }
  }
  .order-inspection-container {
    .card-container {
      height: 134px;
    }
  }
  .customer-service-container {
    .card-container {
      height: 377px;
      display: flex;
      justify-content: space-evenly;
      .single-card {
        flex: 1;
        width: 0;
        background: #ffffff;
        border-radius: 4px;
        padding: 24px;
      }
      .single-card + .single-card {
        margin-left: 16px;
      }
    }
  }
}
</style>