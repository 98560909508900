<template>
  <div class="meter-reading-component">
    <div class="card-container" v-loading="listLoading">
      <div v-if="buildingList.length">
        <el-row :gutter="16">
          <el-col :span="5" v-for="(item, index) in buildingList" :key="index">
            <div class="single-item">
              <div class="title-line">
                {{ item.label }}
              </div>
              <div class="list-container">
                <div
                  class="single-list-item"
                  v-for="(sub, subIndex) in item.dataList"
                  :key="subIndex"
                >
                  <span :class="[`icon-span-${sub.type}`, `icon-span`]"> </span>
                  <span class="label-span">
                    {{ sub.label }}
                  </span>
                  <span class="right-part" @click="jumpToRspace(sub)">
                    <span> 本月导入 </span>
                    <span class="right-value-span"> {{ sub.value }} </span>
                    <span class="arrow-span">
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-empty class="no-data-container" description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getMeterDatas } from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "meterReadingComponent",
  data() {
    return {
      storeId: "",
      listLoading: false,
      buildingList: [],
    };
  },
  mounted() {
    this.$eventBus.$on("propertyStatisticsFresh", ({ index, projectId }) => {
      this.storeId = projectId;
      this.initDatas(projectId);
    });
  },
  methods: {
    jumpToRspace(item) {
      const { storeId, buildingId, meterType } = item.subItem;
      const [start, end] = this.getcurrentMonth();
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/equipment?log=1&type=${meterType}&storeId=${storeId}&buildingId=${buildingId}&sid=${
        this.storeId
      }&start=${start}&end=${end}`;
      window.open(url, "_blank");
    },
    getcurrentMonth() {
      const currentDate = new Date();
      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastLate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      console.log("firstDate", firstDate, lastLate);
      return [firstDate.getTime(), lastLate.getTime()];
    },
    initDatas(store_id) {
      this.listLoading = true;
      getMeterDatas({ store_id, token: this.$store.getters.baseAuthToken })
        .then((res) => {
          if (res.data) {
            const meterTypeMap = {
              1: "水",
              2: "电",
              3: "燃气",
              4: "空调",
            };
            const meterTypeNameMap = {
              1: "water",
              2: "electricity",
              3: "gas",
              4: "airconditioning",
            };
            this.buildingList = res.data.map((item) => {
              return {
                label: item.buildingName,
                dataList: item.detail.map((subItem) => {
                  return {
                    label: meterTypeMap[subItem.meterType],
                    value: subItem.count,
                    type: meterTypeNameMap[subItem.meterType],
                    subItem,
                  };
                }),
              };
            });
          }
        })
        .catch(() => {
          this.buildingList = [];
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.meter-reading-component {
  height: 100%;
  .card-container {
    height: 100%;
    .single-item {
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      margin-bottom: 16px;
      .title-line {
        height: 48px;
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        line-height: 24px;
        border-bottom: 1px solid #ebeef5;
      }
      .list-container {
        margin-top: 10px;
        .single-list-item {
          height: 35px;
          display: flex;
          align-items: center;
          .icon-span {
            display: inline-block;
            margin-right: 5px;
            height: 16px;
            width: 16px;
          }
          .icon-span-water {
            background: url("../../../../../../../assets/images/bms/instrument-water-icon.png");
          }
          .icon-span-electricity {
            background: url("../../../../../../../assets/images/bms/instrument-electricity-icon.png");
          }
          .icon-span-gas {
            background: url("../../../../../../../assets/images/bms/instrument-gas-icon.png");
          }
          .icon-span-airconditioning {
            background: url("../../../../../../../assets/images/bms/instrument-airconditioning-icon.png");
          }
          .label-span {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #2f3941;
            line-height: 20px;
            flex: 1;
          }
          .right-part {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #9099a9;
            cursor: pointer;
            line-height: 20px;
            .right-value-span {
              text-align: right;
              display: inline-block;
              width: 28px;
            }
            .arrow-span {
              color: #1a4cec;
              font-weight: bold;
              margin-left: 5px;
              i {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .no-data-container {
      background: #ffffff;
      border-radius: 4px;
      height: 304px;
      width: 100%;
    }
  }
}
</style>