<template>
  <div class="instrument-management-component">
    <div class="card-container" v-loading="listLoading">
      <div v-if="buildingList.length">
        <el-row :gutter="16">
          <el-col :span="5" v-for="(item, index) in buildingList" :key="index">
            <div class="single-item">
              <div class="title-line">
                {{ item.label }}
              </div>
              <el-row :gutter="12" class="sub-container">
                <el-col
                  :span="12"
                  v-for="(sub, subIndex) in item.dataList"
                  :key="subIndex"
                >
                  <div class="sub-card">
                    <div class="first-line">
                      <span :class="[`icon-span-${sub.type}`, `icon-span`]">
                      </span>
                      <span class="label-span">
                        {{ sub.label }}
                      </span>
                    </div>
                    <div class="second-line" @click="jumpToRspace(sub)">
                      <span class="value-span">
                        {{ sub.value }}
                      </span>
                      <span class="arrow-span">
                        <i class="el-icon-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else class="no-data-container">
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getInstrumentsDatas } from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "instrumentManagementComponent",
  data() {
    return {
      storeId: "",
      listLoading: false,
      buildingList: [],
    };
  },
  mounted() {
    this.$eventBus.$on("propertyStatisticsFresh", ({ index, projectId }) => {
      this.storeId = projectId;
      this.initDatas(projectId);
    });
  },
  methods: {
    jumpToRspace(item) {
      const { storeId, buildingId, meterType } = item.subItem;
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/equipment?type=${meterType}&storeId=${storeId}&buildingId=${buildingId}&sid=${
        this.storeId
      }`;
      window.open(url, "_blank");
    },
    initDatas(store_id) {
      this.listLoading = true;
      getInstrumentsDatas({
        store_id,
        token: this.$store.getters.baseAuthToken,
      })
        .then((res) => {
          const meterTypeMap = {
            1: "水",
            2: "电",
            3: "燃气",
            4: "空调",
          };
          const meterTypeNameMap = {
            1: "water",
            2: "electricity",
            3: "gas",
            4: "airconditioning",
          };
          this.buildingList = res.data.map((item) => {
            return {
              label: item.buildingName,
              dataList: item.detail.map((subItem) => {
                return {
                  label: meterTypeMap[subItem.meterType],
                  value: subItem.count,
                  type: meterTypeNameMap[subItem.meterType],
                  subItem,
                };
              }),
            };
          });
        })
        .catch(() => {
          this.buildingList = [];
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.instrument-management-component {
  height: 100%;
  .card-container {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    .single-item {
      flex: 1;
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      margin-bottom: 16px;
      .title-line {
        height: 48px;
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        line-height: 24px;
        border-bottom: 1px solid #ebeef5;
      }
      .sub-container {
        margin-top: 24px;
        .sub-card {
          height: 80px;
          background: #ebeef5;
          border-radius: 2px 2px 2px 2px;
          margin-bottom: 12px;
          padding: 8px;
          &:hover {
            background: #1a4cec;
            .first-line {
              .icon-span-water {
                background: url("../../../../../../../assets/images/bms/instrument-water-icon-active.png");
              }
              .icon-span-electricity {
                background: url("../../../../../../../assets/images/bms/instrument-electricity-icon-active.png");
              }
              .icon-span-gas {
                background: url("../../../../../../../assets/images/bms/instrument-gas-icon-active.png");
              }
              .icon-span-airconditioning {
                background: url("../../../../../../../assets/images/bms/instrument-airconditioning-icon-active.png");
              }
              .label-span {
                color: #ffffff;
              }
            }
            .second-line {
              .value-span {
                color: #ffffff;
              }
              .arrow-span {
                color: #ffffff;
              }
            }
          }
          .first-line {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            & > span {
              display: inline-block;
            }
            .icon-span {
              margin-right: 2px;
              height: 16px;
              width: 16px;
            }
            .icon-span-water {
              background: url("../../../../../../../assets/images/bms/instrument-water-icon.png");
            }
            .icon-span-electricity {
              background: url("../../../../../../../assets/images/bms/instrument-electricity-icon.png");
            }
            .icon-span-gas {
              background: url("../../../../../../../assets/images/bms/instrument-gas-icon.png");
            }
            .icon-span-airconditioning {
              background: url("../../../../../../../assets/images/bms/instrument-airconditioning-icon.png");
            }
            .label-span {
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #2f3941;
              line-height: 20px;
            }
          }
          .second-line {
            height: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .value-span {
              height: 20px;
              font-size: 20px;
              font-weight: bold;
              color: #3b454c;
              line-height: 20px;
            }
            .arrow-span {
              color: #1a4cec;
              font-weight: bold;
            }
          }
        }
      }
    }
    .no-data-container {
      background: #ffffff;
      border-radius: 4px;
      height: 304px;
      width: 100%;
    }
  }
}
</style>