<template>
  <div class="new-payment-component">
    <div class="header-line">
      <div class="title-line">
        <img src="@/assets/images/bms/new-payment-icon.png" alt="" />
        <span class="title-span"> 本月新增缴费 </span>
      </div>
      <div class="count-line">
        {{ count }}
        <span class="unit-span"> 笔 </span>
      </div>
    </div>
    <div v-if="count !== 0">
      <div class="list-container">
        <div
          class="single-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="jumpToSeachList(item)"
        >
          <span class="detail-span">
            {{ item.detail }}
          </span>
          <span class="value-span">
            {{ item.value }}
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
      </div>
      <div class="view-all-line">
        <ViewAllButton :count="count" @click="viewAllHandler" />
      </div>
    </div>
    <div v-else>
      <el-empty class="no-data-container" decription="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { getPaymentTotal } from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
import ViewAllButton from "../../../components/ViewAllButton";
import { dateFormat } from "@/filters/index.js";
export default {
  name: "newPaymentComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      count: 0,
      dataList: [
        {
          detail: "月卡小计",
          value: 0,
          key: "monthCarCount",
        },
        {
          detail: "临时车小计",
          value: 0,
          key: "tmpCount",
        },
      ],
    };
  },
  mounted() {
    this.$eventBus.$on(
      "propertyStatisticsFresh",
      ({ index, projectId, rugeProjectId }) => {
        this.initDatas(rugeProjectId);
      }
    );
  },
  methods: {
    jumpToSeachList({ key }) {
      const [startDate, endDate] = this.getcurrentMonth();
      const param = {
        orderType: key === "tmpCount" ? "parkPay" : "monthPay",
        status: "已支付",
        startDate: dateFormat(startDate, "YYYY-MM-DD"),
        endDate: dateFormat(endDate, "YYYY-MM-DD"),
      };
      sessionStorage.setItem(
        "payment_list_query_params",
        JSON.stringify(param)
      );
      this.jumpToBmsService();
    },
    getcurrentMonth() {
      const currentDate = new Date();
      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastLate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      console.log("firstDate", firstDate, lastLate);
      return [firstDate.getTime(), lastLate.getTime()];
    },
    initDatas(rugeProjectId) {
      getPaymentTotal({ rugeProjectId }).then((res) => {
        const { count, monthCarCount, tmpCount } = res || {};
        this.count = count;
        this.dataList.forEach((item) => {
          if (item.key === "monthCarCount") {
            item.value = monthCarCount;
          }
          if (item.key === "tmpCount") {
            item.value = tmpCount;
          }
        });
      });
    },
    viewAllHandler() {
      const [startDate, endDate] = this.getcurrentMonth();
      const param = {
        status: "已支付",
        startDate: dateFormat(startDate, "YYYY-MM-DD"),
        endDate: dateFormat(endDate, "YYYY-MM-DD"),
      };
      sessionStorage.setItem(
        "payment_list_query_params",
        JSON.stringify(param)
      );
      this.jumpToBmsService();
    },
    jumpToBmsService() {
      const url = `${location.origin}${location.pathname}#/bms/parkAffairs/paymentRecord?goBackFlag=true`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.new-payment-component {
  .no-data-container {
    height: 230px;
  }
  .header-line {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .title-line {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .title-span {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #3b454c;
        line-height: 24px;
        margin-left: 5px;
      }
    }

    .count-line {
      height: 40px;
      line-height: 40px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
      .unit-span {
        font-size: 16px;
        color: #798aa5;
        position: relative;
        top: -2px;
      }
    }
  }
  .list-container {
    margin: 15px 0;
    height: 150px;
    .single-item {
      height: 30px;
      align-items: center;
      display: flex;
      .detail-span {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        line-height: 20px;
        cursor: pointer;
      }
      .value-span {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        width: 60px;
        text-align: right;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
        line-height: 20px;
        .el-icon-arrow-right {
          font-size: 16px;
          font-weight: bold;
          color: #1a4cec;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>