var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "meter-reading-component" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "card-container",
      },
      [
        _vm.buildingList.length
          ? _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 16 } },
                  _vm._l(_vm.buildingList, function (item, index) {
                    return _c("el-col", { key: index, attrs: { span: 5 } }, [
                      _c("div", { staticClass: "single-item" }, [
                        _c("div", { staticClass: "title-line" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.label) +
                              "\n            "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-container" },
                          _vm._l(item.dataList, function (sub, subIndex) {
                            return _c(
                              "div",
                              {
                                key: subIndex,
                                staticClass: "single-list-item",
                              },
                              [
                                _c("span", {
                                  class: [`icon-span-${sub.type}`, `icon-span`],
                                }),
                                _c("span", { staticClass: "label-span" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(sub.label) +
                                      "\n                "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "right-part",
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpToRspace(sub)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v(" 本月导入 ")]),
                                    _c(
                                      "span",
                                      { staticClass: "right-value-span" },
                                      [_vm._v(" " + _vm._s(sub.value) + " ")]
                                    ),
                                    _c("span", { staticClass: "arrow-span" }, [
                                      _c("i", {
                                        staticClass: "el-icon-arrow-right",
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  }),
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("el-empty", {
                  staticClass: "no-data-container",
                  attrs: { description: "暂无数据" },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }