<template>
  <div class="high-frequency-word">
    <div class="header-line">
      <div class="title-line">
        <img src="@/assets/images/bms/high-frequency-icon.png" alt="" />
        <span class="title-span"> 客户报修高频词汇分析 </span>
      </div>
    </div>
    <div id="word_cloud_view_id" class="high-frequency-container"></div>
  </div>
</template>

<script>
// https://blog.csdn.net/a787373009/article/details/102615282?utm_medium=distribute.pc_relevant.none-task-blog-2~default~baidujs_baidulandingword~default-0-102615282-blog-124190342.pc_relevant_recovery_v2&spm=1001.2101.3001.4242.1&utm_relevant_index=3
import { queryFactWord } from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
import { dateFormat } from "@/filters/index";
import * as echarts from "echarts";
require("echarts-wordcloud");
import "echarts/theme/macarons.js";
export default {
  name: "highFrequencyWord",
  data() {
    return {
      word_cloud_view: null,
      word_cloud_options: {},
      word_list: [
        // { name: "一楼", value: 234 },
        // { name: "地库", value: 185 },
        // { name: "空调", value: 184 },
        // { name: "电梯", value: 170 },
        // { name: "洗手间", value: 162 },
        // { name: "脱落", value: 158 },
        // { name: "漏水", value: 158 },
        // { name: "西面", value: 152 },
        // { name: "坏", value: 150 },
        // { name: "安装", value: 146 },
        // { name: "检修", value: 129 },
        // { name: "东面", value: 123 },
        // { name: "饭堂", value: 118 },
        // { name: "更换", value: 117 },
        // { name: "松动", value: 116 },
        // { name: "维修", value: 114 },
        // { name: "灯不亮", value: 113 },
        // { name: "办公室", value: 112 },
        // { name: "厅", value: 103 },
        // { name: "北面", value: 100 },
      ],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    draw_word_cloud_view() {
      if (this.word_cloud_view) {
        this.word_cloud_view.dispose();
      }
      this.word_cloud_view =
        document.getElementById("word_cloud_view_id") &&
        echarts.init(document.getElementById("word_cloud_view_id"), "macarons");
      this.word_cloud_view &&
        this.word_cloud_view.setOption(this.word_cloud_options);
    },
    init_view_data() {
      let word_cloud_series = [
        {
          type: "wordCloud",
          shape: "circle",
          //maskImage: maskImage,
          left: "center",
          top: "center",
          width: "100%",
          height: "100%",
          right: null,
          bottom: null,
          sizeRange: [6, 60],
          rotationRange: [-45, 90],
          autoSize: {
            enable: true,
            minSize: 12,
          },
          textPadding: 0,
          // rotationStep: 45,
          // gridSize: 8,
          drawOutOfBound: false,
          textStyle: {
            normal: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 256),
                    Math.round(Math.random() * 256),
                    Math.round(Math.random() * 256),
                  ].join(",") +
                  ")"
                );
              },
            },
            emphasis: {
              shadowBlur: 2,
              shadowColor: "#333",
            },
          },
          data: this.word_list,
        },
      ];

      this.word_cloud_options = {
        series: word_cloud_series,
      };
      this.word_cloud_view.setOption(this.word_cloud_options);
    },
    initDatas() {
      const diff = 1000 * 60 * 60 * 24 * 90;
      const last3MonthStamp = new Date().getTime() - diff;
      queryFactWord({
        // rugeProjectId,
        startTime: dateFormat(last3MonthStamp, "YYYY-MM-DD"),
        endTime: dateFormat(new Date(), "YYYY-MM-DD"),
      })
        .then((res) => {
          this.word_list = res
            .sort((a, b) => {
              return b.value - a.value;
            })
            .slice(0, 25);
        })
        .finally(() => {
          this.draw_word_cloud_view();
          this.init_view_data();
        });
    },
  },
  // mounted() {
  //   this.$eventBus.$on(
  //     "propertyStatisticsFresh",
  //     ({ index, projectId, rugeProjectId }) => {
  //       this.initDatas(rugeProjectId);
  //     }
  //   );
  // },
  render(h) {
    return h("div", {
      attrs: { id: "word_cloud_view_id" },
      style: {
        height: "400px",
        width: "400px",
      },
    });
  },
};
</script>

<style lang="less" scoped>
.high-frequency-word {
  .header-line {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .title-line {
      display: flex;
      align-items: center;
      .title-span {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #3b454c;
        line-height: 24px;
        margin-left: 5px;
      }
    }
  }
  .high-frequency-container {
    height: 275px;
    width: 100%;
    margin-top: 15px;
  }
}
</style>