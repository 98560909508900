var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "high-frequency-word" }, [
      _c("div", { staticClass: "header-line" }, [
        _c("div", { staticClass: "title-line" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/bms/high-frequency-icon.png"),
              alt: "",
            },
          }),
          _c("span", { staticClass: "title-span" }, [
            _vm._v(" 客户报修高频词汇分析 "),
          ]),
        ]),
      ]),
      _c("div", {
        staticClass: "high-frequency-container",
        attrs: { id: "word_cloud_view_id" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }