var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "average-score-component" }, [
    _c("div", { staticClass: "header-line" }, [
      _vm._m(0),
      _c("div", { staticClass: "count-line" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.avgGrade ? _vm.avgGrade.toFixed(1) : 0) +
            "\n    "
        ),
      ]),
    ]),
    _vm.dataList.length !== 0
      ? _c("div", [
          _c(
            "div",
            { staticClass: "list-container" },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "single-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "detail-span",
                    on: { click: _vm.viewAllHandler },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.description || "-") +
                        "\n        "
                    ),
                  ]
                ),
                _c("span", { staticClass: "value-span" }, [
                  _vm._v("\n          " + _vm._s(item.gradeNum) + "\n        "),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "view-all-line" },
            [
              _c("ViewAllButton", {
                attrs: { count: _vm.count },
                on: { click: _vm.viewAllHandler },
              }),
            ],
            1
          ),
        ])
      : _c(
          "div",
          [
            _c("el-empty", {
              staticClass: "no-data-container",
              attrs: { decription: "暂无数据" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/average-score-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [
        _vm._v(" 本月用户评价平均分 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }