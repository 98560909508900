<template>
  <div class="order-and-inspection">
    <div class="card-container" v-loading="listLoading">
      <div class="single-item" v-for="(item, index) in dataList" :key="index" @click.stop="pageJump(item)">
        <div class="label-line">
          <img
            :src="
              require(`@/assets/images/bms/order-inspection-${item.type}.png`)
            "
            alt=""
          />
          <span class="label-span">
            {{ item.label }}
          </span>
        </div>
        <div class="value-line">
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getInspectionTotal,
  getOrderTotal,
} from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
export default {
  name: "orderAndInspection",
  data() {
    return {
      listLoading: false,
      projectId:'',
      dataList: [
        {
          key: "orderCount",
          businessType: "order",
          label: "本月全部工单",
          value: 0,
          type: "allOrder",
          toUrls:"/tenant/order/orderQuery/order/all",
        },
        {
          key: "notFinishCount",
          businessType: "order",
          label: "本月未完成工单",
          value: 0,
          type: "monthOrder",
          toUrls:"/tenant/order/orderQuery/order/all",
        },
        {
          key: "avgFinishTime",
          businessType: "order",
          label: "本月工单平均完成时长 (分钟)",
          value: 0,
          type: "completeAvg",
          toUrls:"/tenant/order/orderQuery/order/all",
        },
        {
          key: "inspectionCount",
          businessType: "inspection",
          label: "本月全部巡检",
          value: 0,
          type: "monthInspection",
          toUrls:"/tenant/inspection/inspectionSearch/list",
        },
        {
          key: "notFinishCount",
          businessType: "inspection",
          label: "本月未完成巡检",
          value: 0,
          type: "unCompleteInspection",
          toUrls:"/tenant/inspection/inspectionSearch/list",
        },
      ],
    };
  },
  mounted() {
    this.$eventBus.$on(
      "propertyStatisticsFresh",
      ({ index, projectId, rugeProjectId }) => {
        this.initDatas(rugeProjectId);
      }
    );
  },
  methods: {
    resetCount() {
      this.dataList.forEach((item) => {
        item.value = 0;
      });
    },
    initDatas(rugeProjectId) {
    	this.projectId=rugeProjectId;
      this.resetCount();
      this.listLoading = true;
      Promise.all([
        this.getInspectionDatas(rugeProjectId),
        this.getOrderDatas(rugeProjectId),
      ])
        .then((res) => {
          const inspectionObj = res[0];
          const orderObj = res[1];
          this.dataList.forEach((item) => {
            if (item.businessType === "order") {
              if (orderObj.hasOwnProperty(item.key)) {
                item.value = orderObj[item.key];
              }
            }
            if (item.businessType === "inspection") {
              if (inspectionObj.hasOwnProperty(item.key)) {
                item.value = inspectionObj[item.key];
              }
            }
          });
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    pageJump(item){
    	//页面跳转
    	if(item.toUrls){
    		let params={}
	      let Arrs1=[];
	      if(item.type=='monthOrder'){
	      	//本月未完成工单 未完成  报单待审核、报单被驳回、待领取、待执行、执行中、待验收、验收被驳回、报单已撤销
	      	Arrs1=['0','1','2','3','4','6','7','8'];
	      }
	      if(item.type=='completeAvg'){
	      	//工单平均时长=已完成
	      	Arrs1=['5'];
	      }
	      if(item.type=='unCompleteInspection'){
	      	Arrs1=[1,2,3];
	      	//本月未完成巡检 默认选中【待领取、待执行、执行中】
	      }
	      
	      
	      let datas = {
	        path: item.toUrls,
	        query:{
	        	companyId:4,
	        	projectId:this.projectId,
	        	bmsType:item.key,
	        	statuArrs:JSON.stringify(Arrs1),
	        	...this.common.getMonFristEndDay(),
	        },
	      };
	      //设置跳转参数
//	      this.common.bmsJumpDatas={
//	      	type:item.key,
//	      	statuArrs:Arrs1,
//	      	...this.common.getMonFristEndDay(),
//	      }
//	      console.log('3333',datas,this.common.bmsJumpDatas);
	    	this.$router.push(datas);
    	}
    	
    	
    	
    },
    getInspectionDatas(rugeProjectId) {
      return getInspectionTotal({ rugeProjectId });
    },
    getOrderDatas(rugeProjectId) {
      return getOrderTotal({ rugeProjectId });
    },
  },
};
</script>

<style lang="less" scoped>
.order-and-inspection {
  height: 100%;
  .card-container {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    .single-item {
    	cursor: pointer;
      flex: 1;
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      .label-line {
        line-height: 24px;
        height: 44px;
        display: flex;
        align-items: center;
        .label-span {
          font-size: 16px;
          font-weight: 500;
          color: #2f3941;
          margin-left: 5px;
        }
      }
      .value-line {
        height: 46px;
        font-size: 36px;
        font-weight: bold;
        color: #3b454c;
        line-height: 46px;
      }
    }
    .single-item + .single-item {
      margin-left: 16px;
    }
  }
}
</style>