<template>
  <div class="average-score-component">
    <div class="header-line">
      <div class="title-line">
        <img src="@/assets/images/bms/average-score-icon.png" alt="" />
        <span class="title-span"> 本月用户评价平均分 </span>
      </div>
      <div class="count-line">
        {{ avgGrade ? avgGrade.toFixed(1) : 0 }}
      </div>
    </div>
    <div v-if="dataList.length !== 0">
      <div class="list-container">
        <div class="single-item" v-for="(item, index) in dataList" :key="index">
          <span class="detail-span" @click="viewAllHandler">
            {{ item.description || "-" }}
          </span>
          <span class="value-span">
            {{ item.gradeNum }}
          </span>
        </div>
      </div>
      <div class="view-all-line">
        <ViewAllButton :count="count" @click="viewAllHandler" />
      </div>
    </div>
    <div v-else>
      <el-empty class="no-data-container" decription="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { queryMonthGrade } from "@/api/ruge/bms/integratedManagementPlatform/propertyStatistics.js";
import ViewAllButton from "../../../components/ViewAllButton";
export default {
  name: "averageScoreComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      avgGrade: 0,
      count: 0,
      dataList: [],
    };
  },
  mounted() {
    this.$eventBus.$on(
      "propertyStatisticsFresh",
      ({ index, projectId, rugeProjectId }) => {
        this.initDatas(rugeProjectId);
      }
    );
  },
  methods: {
    getcurrentMonth() {
      const currentDate = new Date();
      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastLate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      console.log("firstDate", firstDate, lastLate);
      return [firstDate.getTime(), lastLate.getTime()];
    },
    initDatas(rugeProjectId) {
      queryMonthGrade({ rugeProjectId }).then((res) => {
        console.log("rugeProjectId", rugeProjectId);
        const { avgGrade, total, data } = res || {};
        this.avgGrade = avgGrade || 0;
        this.count = total || 0;
        this.dataList = data.slice(0, 5);
      });
    },
    viewAllHandler() {
      const [startDate, endDate] = this.getcurrentMonth();
      const param = {
        startTime: startDate,
        endTime: endDate,
      };
      sessionStorage.setItem(
        "evaluation_list_query_params",
        JSON.stringify(param)
      );
      const url = `${location.origin}${location.pathname}#/bms/parkAffairs/userEvaluation`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.average-score-component {
  .no-data-container {
    height: 230px;
  }
  .header-line {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebeef5;
    .title-line {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .title-span {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #3b454c;
        line-height: 24px;
        margin-left: 5px;
      }
    }

    .count-line {
      height: 40px;
      line-height: 40px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .list-container {
    margin: 15px 0;
    height: 150px;
    .single-item {
      height: 30px;
      align-items: center;
      display: flex;
      .detail-span {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        line-height: 20px;
        cursor: pointer;
      }
      .value-span {
        display: inline-block;
        width: 30px;
        text-align: right;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
        line-height: 20px;
      }
    }
  }
}
</style>