var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new-suggestion-component" }, [
    _c("div", { staticClass: "header-line" }, [
      _vm._m(0),
      _c("div", { staticClass: "count-line" }, [
        _vm._v("\n      " + _vm._s(_vm.count) + "\n    "),
      ]),
    ]),
    _vm.dataList.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "list-container" },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "single-item" }, [
                _c("span", { staticClass: "detail-span" }, [
                  _vm._v(
                    "\n          " + _vm._s(item.problemDesc) + "\n        "
                  ),
                ]),
                _c("span", { staticClass: "date-span" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("dateFormat")(item.creationDate, "YYYY-MM-DD")
                      ) +
                      "\n        "
                  ),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "view-all-line" },
            [
              _c("ViewAllButton", {
                attrs: { count: _vm.count },
                on: { click: _vm.viewAllHandler },
              }),
            ],
            1
          ),
        ])
      : _c(
          "div",
          [
            _c("el-empty", {
              staticClass: "no-data-container",
              attrs: { description: "暂无数据" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/new-suggestion-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [_vm._v(" 本月新增投诉建议 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }