import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 仪表管理
 * @param params
 */
export function getInstrumentsDatas(params) {
  return request({
    url: '/api/rx_lease/inner/meter_statistics',
    method: 'get',
    params,
  });
}

/**
 * 抄表管理
 * @param params
 */
export function getMeterDatas(params) {
  return request({
    url: '/api/rx_lease/inner/gen_bills_statistics',
    method: 'get',
    params,
  });
}

/**
 * 工单 & 巡检 - 巡检
 * @param params
 */
export function getInspectionTotal(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rworkData/inspectionCountByMonth',
    method: 'get',
    params,
  });
}

/**
 * 工单 & 巡检 - 工单
 * @param params
 */
export function getOrderTotal(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rworkData/orderCountByMonth',
    method: 'get',
    params,
  });
}

/**
 * 本月新增投诉建议
 * @param params
 */
export function getSuggestionTotal(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/customerData/queryMonthComplaintOrder',
    method: 'get',
    params,
  });
}

/**
 * 本月新增缴费
 * @param params
 */
export function getPaymentTotal(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/customerData/queryMonthPayFee',
    method: 'get',
    params,
  });
}

/**
 * 词云
 * @param params
 */
export function queryFactWord(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/customerData/queryFactWord',
    method: 'get',
    params,
  });
}

/**
 * 本月用户评价平均分
 * @param params
 */
export function queryMonthGrade(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/customerData/queryMonthGrade',
    method: 'get',
    params,
  });
}

/**
 * 本月开具电子发票
 * @param params
 */
export function queryMonthInvoice(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/customerData/queryMonthInvoice',
    method: 'get',
    params,
  });
}
